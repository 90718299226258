/**
 * Const with params element
 * CTA Button download SVG component
 * Display a link as a button with download
 */
/* Import section */
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { IconContext } from "react-icons"
import { BsDownload } from "react-icons/bs"
import "../styles/components/ctabuttondownload.scss"

/* Declaration function */
const Ctabuttondownload = ({ idCta, classCta, ctaLabel, ctaLink, extLink }) => {
  const defaultStyle = "cta-button-download"

  if(extLink) {
    return (
      <a id={idCta} className={`${defaultStyle} ${classCta}`} href={ctaLink} target="_blank" rel="noreferrer" aria-label={ctaLabel}>
        <Wrapspanbuttondownload ctaLabel={ctaLabel} />
      </a>
    )
  } else {
    return (
      <Link id={idCta} className={`${defaultStyle} ${classCta}`} to={`${ctaLink}/`} aria-label={ctaLabel}>
        <Wrapspanbuttondownload ctaLabel={ctaLabel} />
      </Link>
      )
  }
}

/* Function propTypes declaration */
Ctabuttondownload.propTypes = {
  idCta: PropTypes.string,
  classCta: PropTypes.string,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string,
  extLink: PropTypes.bool
}

Ctabuttondownload.defaultProps = {
  idCta: "",
  classCta: "",
  ctaLabel: "Click here",
  ctaLink: "/",
  extLink: false
}

/* Export function */
export default Ctabuttondownload

/* Wrapspanbuttondownload function */
const Wrapspanbuttondownload = ({ ctaLabel }) => {
  return (
    <span className="wrap-spanbutton">
      <span className="spanbutton-label">{ctaLabel}</span>
      <span className="spanbutton-download">
        <IconContext.Provider value={{ className: "icon-download" }}>
          <BsDownload />
        </IconContext.Provider>
      </span>
    </span>
  )
}
