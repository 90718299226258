/**
 * Const with params element
 * Card Video component
 * Display a card of summary Video
 */
/* Import section */
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"
import React, { Component } from "react"
import { IconContext } from "react-icons"
import { BsFillPlayFill, BsX } from "react-icons/bs"
import TruncateText from "../components/truncatetext"
import Video from "../components/video"
import "../styles/components/cardvideo.scss"

/* Declaration function */
class Cardvideo extends Component {

  targetElement = null;
  video = null;

  constructor(props) {
    super(props)
    this.state = {
      showLightbox: false
    }
  }

   componentDidUpdate() {
     var indexVideo = this.props.index
     this.targetElement = document.querySelector('#videoLightbox-'+indexVideo)
     this.currentVideoIframe = document.querySelector( '#videoLightbox-'+indexVideo+' .video-foreground iframe')
     console.log(this.currentVideoIframe)
   }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  manageLightbox(boolStateLightbox){
    this.setState({ showLightbox: boolStateLightbox })
    var header = [].slice.call((document.getElementsByClassName("main-header")))

    if(this.targetElement !== null) {
      if(boolStateLightbox === true) {
        disableBodyScroll(this.targetElement);
        this.currentVideoIframe.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'playVideo' }), '*')
        header[0].classList.add("lowzindex")
      }else{
        enableBodyScroll(this.targetElement);
        console.log('TOTO LA')
        this.currentVideoIframe.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'stopVideo' }), '*')
        header[0].classList.remove("lowzindex")
      }
    }
  }

  render () {
    const leadVideo = this.props.leadVideo
    const titleVideo = this.props.titleVideo
    const urlVideo = this.props.slugVideo
    var splitUrlVideo = urlVideo.split("/")
    var sourceVideo = splitUrlVideo[2]
    var indexVideo = this.props.index

    return (
      <div className="wrap-card-video">
        {sourceVideo === "www.youtube.com" ? (
          <div id={ `block-learnvideo-${indexVideo}` }>
            <div className="card-video card-clickable" onClick={() => this.manageLightbox(true)} onKeyDown={() => this.manageLightbox(true)} role="button" tabIndex={-1} aria-label="Open the lightbox">
              <p className="type-training"><span className="video">Video</span></p>
              <h2><TruncateText str={titleVideo} length="60" ending="..." /></h2>
              <div className="box-lead" dangerouslySetInnerHTML={{ __html:leadVideo }} />
              <div className="box-ctatext-card">
                <p className="fake-cta">
                  <span className="wrap-player">
                  <IconContext.Provider value={{ className: "svg-player" }}>
                    <BsFillPlayFill />
                  </IconContext.Provider>
                    <span className="text-cta-square">Watch Video</span>
                  </span>
                </p>
              </div>
            </div>
            {(this.state.showLightbox === true) &&
            <div id={`videoCardLightbox-${indexVideo}`} className="video-lightbox" onClick={() => this.manageLightbox(false)} onKeyDown={() => this.manageLightbox(false)} role="button" tabIndex={0} aria-label="Close the lightbox">
              <button id="player-videopromo" className="button-close-lightbox" onClick={() => this.manageLightbox(false)} aria-label="Close the lightbox">
                <IconContext.Provider value={{ className: "icon-closed-lightbox" }}>
                  <i aria-hidden="true"><BsX /></i>
                </IconContext.Provider>
              </button>
              <Video videoTitle={titleVideo} videoSrcURL={urlVideo} />
            </div>
            }
        </div>
        ) : (
          <div className="card-video card-clickable">
            <p className="type-training"><span className="video">Video</span></p>
            <h2><TruncateText str={titleVideo} length="60" ending="..." /></h2>
            <div className="box-lead" dangerouslySetInnerHTML={{ __html:leadVideo }} />
            <div className="box-ctatext-card">
              <p className="fake-cta">
                <span className="wrap-player">
                <IconContext.Provider value={{ className: "svg-player" }}>
                  <BsFillPlayFill />
                </IconContext.Provider>
                  <span className="text-cta-square">Watch Video</span>
                </span>
              </p>
            </div>
            <a href={`${urlVideo}`} aria-label="Watch Video" target="_blank" rel="noreferrer"><span className="span-cta-card" >Watch Video</span></a>
          </div>
        )}
    </div>
    )
  }
}

/* Export function */
export default Cardvideo
