/**
 * Const with params element
 * Card Guide component
 * Display a card of summary Guide pdf
 */
/* Import section */
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Ctabuttondownload from "../components/ctabuttondownload"
import "../styles/components/cardguide.scss"

/* Declaration function */
const Cardguide = ({ titleGuide, srcImgGuide, slugFileGuide }) => {
  const externalLink = true

  return (
    <div className="card-guide card-clickable">
      <div className="box-image-card">
        <GatsbyImage image={srcImgGuide} alt={`cover ${titleGuide}`} />
      </div>
      <p className="type-training"><span>Guide</span></p>
      <h2 dangerouslySetInnerHTML={{__html:titleGuide}} />
      <div className="box-ctatext-card">
        <Ctabuttondownload ctaLabel="Download" ctaLink={`/pdf/training-guides/${slugFileGuide}`} extLink={externalLink} />
      </div>
      <a href={`/pdf/training-guides/${slugFileGuide}`} aria-label="Download pdf" target="_blank" rel="noreferrer"><span className="span-cta-card">Download</span></a>
    </div>
  )
}

/* Export function */
export default Cardguide

